<template>
  <div class="consult">
    <Title />
    <Navbar @setPageIndex="emitPageIndex" :lists="lists" :disabled="flag" />

    <!--       <div class="consult-info">
        {{pageData.currentPage}} / {{pageData.totalPages}}
      </div> -->

    <section class="consult-container">
      <div class="consult-title">{{ $t("consult_title") }}</div>
      <div class="consult-desc">{{ $t("consult_desc") }}</div>

        <section class="page-router-container">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </section>
   
    </section>
  </div>
</template>

<script>
import Title from "../components/title/Title.vue";
import Navbar from "../components/navbar/Navbar.vue";
import formLists from "../router/formLists";

export default {
  name: "Consult",
  components: {
    Title,
    Navbar,
  },
  data() {
    return {
      pageData: "",
      lists: formLists,
      flag: false,
    };
  },
  methods: {
    emitPageIndex(data) {
      this.pageData = data;
    },
    emitEmitDisabled(data) {
      this.flag = data;
    },
  },
};
</script>

<style scoped lang="scss">
.consult {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fafafa;
  font-family: "MS PGothic, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;";

  .consult-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    background-blend-mode: lighten;
    background-size: cover;
    background-position: center;

    .consult-title {
      font-size: max(22px, 2.2vw);
      font-weight: bold;
      text-align: center;
      margin-top: 60px;
      color: #132d7a;
    }

    .consult-desc {
      padding: 0 10px;
      font-size: max(12px, 1.5vw);
      color: #132d7a;
      font-weight: bold;
      text-align: center;
      white-space: pre-wrap;
    }


      .page-router-container {
        margin:10px 5% 50px 5%;
        width:80%;
        background-color: rgba(255, 255, 255);
        box-shadow: 0px 0px 30px 5px #e8f2f9;
        border-radius: 10px;
        z-index: 5;
      }
      }
}

.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
